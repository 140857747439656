import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import "katex/dist/katex.min.css";
import RemarkMathPlugin from "remark-math";
import RemarkGfmPlugin from "remark-gfm";
import RehypeKatexPlugin from "rehype-katex";
import MarkdownCodeHighlighter from "./MarkdownCodeHighlighter";
// import Image from "next/image";

// Local error boundary specifically for markdown rendering
class MarkdownErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.message || "Unknown error",
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Markdown rendering error:", error);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI when markdown fails to render
      return (
        <div className="p-3 border rounded">
          <p className="text-red-500">
            Unable to display this question due to a configuration issue. Please
            contact the assessment creator to resolve this problem
          </p>
          <details className="mt-2">
            <summary className="text-sm text-gray-600 cursor-pointer">
              View error details
            </summary>
            <pre className="p-2 mt-1 overflow-auto text-xs bg-gray-100 rounded">
              {this.state.errorMessage}
            </pre>
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

function MarkdownRender(props) {
  // Ensure markdown content is valid to prevent errors
  const safeProps = {
    ...props,
    children: typeof props.children === "string" ? props.children : "",
    remarkPlugins: [RemarkMathPlugin, RemarkGfmPlugin],
    rehypePlugins: [RehypeKatexPlugin],
    components: {
      a: ({ href, children }) => (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
      code: MarkdownCodeHighlighter,
      // img: Image,
    },
  };

  return (
    <MarkdownErrorBoundary>
      <ReactMarkdown {...safeProps} />
    </MarkdownErrorBoundary>
  );
}

export default MarkdownRender;
