import AssessmentTimer from "@/components/attempts/AssessmentTimer";
import EndAssessmentSideOver from "@/components/attempts/EndAssessmentSideOver";
import QuestionDisplayComponent from "@/components/attempts/QuestionDisplayComponent";
import QuestionNavigation from "@/components/attempts/QuestionNavigation";
import QuestionStatPopover from "@/components/attempts/QuestionStatPopover";
import TestAttemptController from "@/components/attempts/TestAttemptController";
import InstructionButton from "@/components/attempts/instructionButton";
import QuestionTypeSwitch from "@/components/attempts/questionTypeSwitch";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Suspense, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
// import "react-resizable-panels/style.css";

function TestAttempt() {
  const [endTestSideOverOpen, setEndTestSideOverOpen] = useState(false);
  const [panelSizes, setPanelSizes] = useState([50, 50]);
  const [isMobile, setIsMobile] = useState(false);

  // Check for mobile screen size
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <TestAttemptController>
      {({
        data,
        test_section_question,
        endSectionHandler,
        endTestHandler,
        saveCurrentPosition,
        currentSectionAttemptedQuestionsStats,
        testTimerEnd,
        section_index,
        setCurrentQuestion,
        currentQuestion,
        questions_map,
        questionRef,
        setSectionChoose,
        isLast,
        section_wise_time_spent,
        allQuestionAttempted,
        questionData,
        currentSection,
        isFullScreen,
        openFullscreen,
        closeFullscreen,
      }) => {
        return (
          <main className="flex flex-col w-full h-full text-base bg-slate-50">
            <div className="flex flex-col items-center justify-between gap-3 px-3 py-3 text-white md:flex-row md:gap-5 md:px-12 md:py-5 bg-slate-800">
              <div className="flex items-center justify-between w-full gap-3 md:w-auto md:justify-start">
                <div className="flex items-center gap-2">
                  {data?.tests_by_pk?.university?.icon && (
                    <img
                      src={data?.tests_by_pk?.university?.icon}
                      alt="Icon"
                      className="object-contain w-6 h-6 md:w-8 md:h-8"
                    />
                  )}
                  <span className="text-sm md:text-base">
                    {data?.tests_by_pk?.university?.name ?? "Meritcurve"}
                  </span>
                </div>
                <span className="hidden md:block">|</span>
                <div className="flex-col hidden text-sm md:flex">
                  <span>
                    {data.test_attempts_by_pk.test_participant.user.fullName}
                  </span>
                  <span>{data.tests_by_pk.title}</span>
                </div>
                <div className="flex items-center gap-2 md:hidden">
                  <AssessmentTimer
                    {...{
                      data,
                      section_wise_time_spent,
                      testTimerEnd,
                      endTestHandler,
                      currentSection,
                      endSectionHandler,
                    }}
                  />
                  <button
                    className=""
                    onClick={() =>
                      isFullScreen ? closeFullscreen() : openFullscreen()
                    }
                  >
                    {isFullScreen ? <BiExitFullscreen /> : <BiFullscreen />}
                  </button>
                </div>
              </div>

              <div className="flex items-center justify-between w-full gap-3 mt-2 md:gap-5 md:w-auto md:justify-end md:mt-0">
                <div className="items-center text-xs md:flex md:text-sm">
                  <span className="md:hidden">
                    {data.test_attempts_by_pk.test_participant.user.fullName}
                  </span>
                </div>
                <div className="hidden md:block">
                  <AssessmentTimer
                    {...{
                      data,
                      section_wise_time_spent,
                      testTimerEnd,
                      endTestHandler,
                      currentSection,
                      endSectionHandler,
                    }}
                  />
                </div>
                <div className="hidden md:block">
                  <button
                    className=""
                    onClick={() =>
                      isFullScreen ? closeFullscreen() : openFullscreen()
                    }
                  >
                    {isFullScreen ? <BiExitFullscreen /> : <BiFullscreen />}
                  </button>
                </div>
                <button
                  className="px-3 py-1 text-sm border border-white rounded-md md:px-5 md:text-base whitespace-nowrap"
                  onClick={() => {
                    if (
                      !data.tests_by_pk.all_question_required ||
                      (data.tests_by_pk.all_question_required &&
                        allQuestionAttempted &&
                        data.tests_by_pk.test_sections.every(
                          (el, i) =>
                            i === section_index ||
                            (i !== section_index &&
                              el.test_attempt_sections[0]?.is_completed)
                        ))
                    ) {
                      setEndTestSideOverOpen(true);
                    } else {
                      toast.error("All questions are required to be attempted");
                    }
                  }}
                >
                  Finish Test
                </button>
                {endTestSideOverOpen && (
                  <EndAssessmentSideOver
                    data={data.tests_by_pk}
                    onClose={() => setEndTestSideOverOpen(false)}
                    open={endTestSideOverOpen}
                    onConfirm={async () => {
                      try {
                        await endTestHandler("user");
                        setEndTestSideOverOpen(false);
                      } catch (error) {
                        toast.error("Ending Assessment Failed");
                        console.log("error", error);
                      }
                    }}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col flex-grow mx-2 -mt-2 border rounded-md md:mx-5">
              <div className="flex flex-wrap items-center justify-between gap-2 px-2 py-2 border-b shadow-md md:px-5 bg-slate-50">
                <div className="flex items-center gap-1 md:gap-2">
                  <div className="text-sm md:text-base">Choose Section</div>
                  <button
                    onClick={() => {
                      if (
                        !data.tests_by_pk.all_question_required ||
                        (data.tests_by_pk.all_question_required &&
                          allQuestionAttempted)
                      ) {
                        setSectionChoose(true);
                      } else {
                        toast.error(
                          "All questions are required to be attempted"
                        );
                      }
                    }}
                    className="flex items-center justify-between w-32 gap-2 px-2 py-1 text-sm bg-white border rounded-md md:gap-5 md:px-3 md:py-2 md:text-base md:w-56"
                  >
                    <div className="truncate">{currentSection?.title}</div>
                    <ChevronDownIcon className="flex-shrink-0 w-3 h-3 md:w-4 md:h-4" />
                  </button>
                  <InstructionButton
                    testInstruction={data.tests_by_pk.instruction}
                    sectionInstruction={currentSection?.instructions}
                  />
                </div>
                <div className="flex items-center gap-1 md:gap-2">
                  <div className="flex flex-col gap-1">
                    <QuestionNavigation
                      currentQuestion={currentQuestion}
                      saveCurrentPosition={saveCurrentPosition}
                      setCurrentQuestion={setCurrentQuestion}
                      questions_map={questions_map}
                      questionData={questionData}
                    />
                    <QuestionStatPopover
                      questions_map={questions_map}
                      currentSectionAttemptedQuestionsStats={
                        currentSectionAttemptedQuestionsStats
                      }
                      questionData={questionData}
                      currentSection={currentSection}
                    />
                  </div>
                </div>
              </div>

              {!test_section_question ? (
                <div className="py-40 text-center">No Questions Found</div>
              ) : (
                <PanelGroup
                  direction={isMobile ? "vertical" : "horizontal"}
                  onLayout={(sizes) => setPanelSizes(sizes)}
                  className="flex flex-col items-stretch flex-grow bg-white md:grid"
                >
                  <Panel defaultSize={50} minSize={isMobile ? 20 : 30}>
                    <div className="flex flex-col w-full gap-3 overflow-y-auto h-[300px] md:h-full">
                      <QuestionDisplayComponent
                        is_marked={
                          test_section_question?.test_question_submissions[0]
                            ?.is_marked
                        }
                        test_attempt_id={data.test_attempts_by_pk.id}
                        test_section_id={
                          data.test_attempts_by_pk.current_section_id
                        }
                        test_section_question_id={test_section_question.id}
                        title={test_section_question.question.data.title}
                        currentQuestion={currentQuestion}
                        test_section_question={test_section_question}
                        section={currentSection}
                        hide_marks={data.tests_by_pk.hide_marks}
                      />
                    </div>
                  </Panel>

                  <PanelResizeHandle
                    className={`${
                      isMobile ? "h-2" : "w-2"
                    } bg-gray-200 hover:bg-gray-300 transition-colors`}
                  />

                  <Panel defaultSize={50} minSize={isMobile ? 20 : 30}>
                    <div className="flex flex-col flex-grow bg-white h-[300px] md:h-full overflow-y-auto p-2 md:p-3">
                      <Suspense
                        fallback={
                          <div className="flex items-center justify-center w-full min-h-[200px] md:min-h-[500px] text-lg md:text-2xl font-semibold">
                            <div className="w-5 h-5 md:w-6 md:h-6 spinner-grow" />
                            <div className="ml-3">Loading</div>
                          </div>
                        }
                      >
                        <QuestionTypeSwitch
                          ref={questionRef}
                          key={
                            test_section_question?.id ?? "no_question_switch"
                          }
                          type={test_section_question.question.type}
                          data={data}
                          test_section_question={test_section_question}
                          next={() => {
                            const nextQuestion =
                              questionData?.test_section_questions?.[
                                questions_map?.[currentQuestion + 1]
                              ];

                            if (nextQuestion) {
                              setCurrentQuestion(currentQuestion + 1);
                              saveCurrentPosition(nextQuestion.id);
                            }
                          }}
                        />
                      </Suspense>
                    </div>
                  </Panel>
                </PanelGroup>
              )}
            </div>
          </main>
        );
      }}
    </TestAttemptController>
  );
}

export default TestAttempt;
